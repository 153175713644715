import request from '@/api/request.js'

/**
 * @Description: 收货单
 */

// 分页查询
export function pageWmsReceiveOrder(data) {
    return request({
        url: '/api/wmsReceiveOrder/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsReceiveOrder(data) {
    return request({
        url: '/api/wmsReceiveOrder/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsReceiveOrder(data) {
    return request({
        url: '/api/wmsReceiveOrder/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsReceiveOrder(data) {
    return request({
        url: '/api/wmsReceiveOrder/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsReceiveOrder(data) {
    return request({
        url: '/api/wmsReceiveOrder/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsReceiveOrder(data) {
    return request({
        url: '/api/wmsReceiveOrder/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsReceiveOrder(data) {
    return request({
        url: '/api/wmsReceiveOrder/batchDelete',
        method: 'POST',
        data
    })
}

